import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageFull } from '../../components/imageelements'


// markup
const GettingStartedPage = () => {
    return (
      <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Getting Started With Opera | The Opera 101</title>
            <html lang="en-us" />
            <link rel="canonical" href="https://theopera101.com/operaabc/gettingstarted" />
            <meta name="description" content="A very brief getting started guide to going to the opera" />
          </Helmet>
          <Hero title="Where to Start!" subtitle="A few reassuring answers"/>
          <MainBodySection>

            <Header title="Should I go to the opera?"/>

            <p>Yes! Many people fret about what’s a safe first choice, but perfect can be the enemy of the good here, and the easiest solution is just to go to whatever sounds fun! Your worst case scenario is leaving early, and whilst operas are frequently long, that’s often in part because they have multiple long intervals. Go with a friend and have a grand time, if it’s a terrible show, at least you’ll have a happy evening commiserating at the bar.</p>

            <div id="ezoic-pub-ad-placeholder-102"> </div>

            <p>Most major towns in the US and Europe have opera houses. Google wherever you are and opera, and you’ll no doubt find something ranging from local companies to touring shows. You might be lucky enough to have a ton of choices or maybe just a few shows a year, but something is likely to be available to you.</p>

            <ImageFull>
                <StaticImage src="../../images/abcs/gettingstarted/met_aida.jpeg" alt="A photo of the Met's Aida" title="The Metropolitan Opera's Aida"/>
                <figcaption>The Metropolitan Opera's absurdly grand Aida &copy; Marty Sohl/Metropolitan Opera</figcaption>
            </ImageFull>

            <Header title="But it’s in Italian?"/>

            <p>Operas have been written in most languages at this point though you’re most likely to hear Italian, French, German, Russian and English opera. This isn’t something you need to worry about. Even small opera houses now have some form of real-time surtitles. This means either somewhere around the stage or on the backs of the seats the text is displayed as it is sung. This can be handy even when you speak the language being sung! Opera plots also tend to be quite simple, so a quick read of a synopsis ahead of time might mean you don’t need to read them at all.</p>

            <ImageFull>
                <StaticImage src="../../images/abcs/gettingstarted/met_titles.jpeg" alt="A photo of the Met's back of seat surtitles" title="The Metropolitan Opera's back of seat surtitles"/>
                <figcaption>The Metropolitan Opera's back of seat surtitles</figcaption>
            </ImageFull>

            <p>The lingo around opera can also be a bit funky, drawing from several languages as a bit of a hodgepodge. We’ve got a glossary to help you out but don’t worry, none of this will stop you from having a great evening out.</p>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>
        
        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

            <Header title="What to wear?"/>

            <p>The vast, vast majority of the time: whatever you like! We personally tend to go incredibly casually. If you’re going to be trapped in a confined space and a potentially uncomfortable seat for an extended period of time, wear what makes you comfortable. If you want to dress up, however: go for it! A bit of flamboyance is always welcome, and you’ll see some fabulous fashion in opera houses.</p>

            <p>There are exceptions, but you need not worry about getting it wrong. Opera houses with dress codes (<Link to="/operahouses/glyndebourne/">Glyndebourne</Link> & <Link to="/operahouses/bayreuth/">Bayreuth</Link> being two of the major ones) make it extremely clear what you’re expected to wear. Sometimes special events, like opening nights, also call for dressier attire, but the tickets will make that clear. To be honest, even these rules are getting laxer, and we’ve seen plenty of people not follow them, long gone are the days of being thrown out for not conforming.</p>

            <Header title="So any first opera recommendations?"/>

            <p>Opinions here range, but we generally recommend operas from the mid-19th through to the mid-20th centuries as good starting points. These have the grandeur and glorious sweep of the Romantic era with music that tends to be beautiful and plots that tend to be faster paced (at least relative to earlier opera) and fun. They also generally come in under three hours! Examples include <Link to="/operas/carmen">Carmen</Link>, <Link to="/operas/onegin">Eugene Onegin</Link>, <Link to="/operas/rigoletto">Rigoletto</Link> and <Link to="/operas/boheme">La bohème</Link> (almost anything by <Link to="/composers/puccini">Puccini</Link> is safe territory). If you want something in English, <Link to="/operas/grimes">Peter Grimes</Link> is a good bet. We don’t generally recommend Mozart’s works to first-timers as they’re mostly masterpieces but extremely long ones. If his music is your bag, though go for it! <Link to="/operas/flute">Magic Flute</Link> is traditionally held as the most accessible but we’d argue for the more straightforward <Link to="/operas/giovanni">Don Giovanni</Link>.</p>

            <ImageFull>
                <StaticImage src="../../images/abcs/gettingstarted/grimes_royalopera.jpeg" alt="Allan Clayton as Peter Grimes at the Royal Opera" title="Allan Clayton as Peter Grimes at the Royal Opera"/>
                <figcaption>Allan Clayton as Peter Grimes &copy; Yasukov Kageyama/Royal Opera</figcaption>
            </ImageFull>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>
        
        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

            <Header title="Is it going to be really long?"/>

            <p>That all depends on what you’re seeing. Check out our <Link to="/operaabc/runningtimes">running times page</Link> for the lengths of much of the operatic repertory. There are plenty of really long operas and some delightfully short ones.</p>

            <Header title="Is it really expensive?"/>

            <p>Sometimes! Live theatre is always going to cost more than a lot of activities but compared to things we might think of as being cheaper, like sports and pop concerts, it really isn’t. Opera houses have a vast range of prices, and there are many ways to get in for far less than you might expect. You’ll have to check with your local opera house but there are often cheaper seats in parts of the house. Also, things like rush or young & senior tickets can get you in for far less than you might expect. All of the writers of this site have at some point bought standing tickets, an incredibly cheap way of getting in the building and often with really great views of the stage.</p>

          </MainBodySection>
      </Layout>
  )
}

export default GettingStartedPage